import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const defaultPlaceholder = "Consulta ingresando tu DNI";

function BarraBusqueda({
  iniciarConsulta,
  dni,
  setDni,
  loading,
  placeholder = defaultPlaceholder,
}) {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <form onSubmit={iniciarConsulta} className="form">
      <input
        className="input-dni"
        placeholder={placeholder}
        value={dni}
        type="number"
        required="required"
        min="0"
        max="9999999999"
        autoComplete="on"
        onChange={(e) => {
          setDni(e.target.value);
        }}
      />
      <button className="submit" type="submit">
        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <span className="pi pi-search"></span>
        )}
      </button>
    </form>
  );
}

export default BarraBusqueda;
