import React from "react";
import LOGOHSRP from "assets/LOGOHSRP.png";

const Logo = () => {
  return (
    <div className="logo-sc">
      <img src={LOGOHSRP} alt="..." />
      <h3>Hospital de la Amistad Perú Corea Santa Rosa II-2</h3>
    </div>
  );
};

export default Logo;
