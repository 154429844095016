import React from "react";
import TicketExport from "../../../../../exports/Ticket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faBell,
  faHandHoldingUsd,
  faCashRegister,
  faCloudDownloadAlt,
  faUserMd,
} from "@fortawesome/free-solid-svg-icons";

export default function Ticket(props) {
  const { key, c } = props;

  const onClick = (ncuenta) => {
    TicketExport(ncuenta);
  };

  return (
    <div className="card-ticket" key = {key}>
      <div className="ticket-header">
          <strong>{c.ESPECIALIDAD}</strong>
          <button onClick={(e)=>onClick(c.CUENTA)} className="imprimir">
          <FontAwesomeIcon icon={faCloudDownloadAlt}/>
          </button>
      </div>
      <div className="ticket-body">
          <p className="colunm-consult">
              <FontAwesomeIcon className="icon-font consulta" icon={faCalendarDay} /> {c.FECHA}
          </p>
          <p className="colunm-consult">
          <FontAwesomeIcon className="icon-font consulta" icon={faBell}/> {c.TURNO}
          </p>
          <p className="colunm-consult">
          <FontAwesomeIcon className="icon-font consulta" icon={faUserMd}/> Dr(a) {c.MEDICO}
          </p>
          <p className="colunm-consult">
          <FontAwesomeIcon className="icon-font consulta" icon={faHandHoldingUsd}/> {c.FUENTEFINANCIAMIENTO}
          </p>
          {c.FUENTEFINANCIAMIENTO !== 'SIS'?
              <p className="colunm-consult">
              <FontAwesomeIcon className="icon-font consulta" icon={faCashRegister}/> {c.ORDENPAGO}
              </p>:<></>
          }
      </div>
  </div>
  );
}
