import React, { useState, useEffect, useRef } from "react";
import Ticket from "../../services/citas-web/Session/components/panel/Ticket";
import BarraBusqueda from "./barraBusqueda";
import { Messages } from "primereact/messages";
import useAxios from "../../App/Hooks/useAxios";
import Logo from "components/Logo";

//import ErrorAlert from '../global/Alertas/ErrorAlert';

function Consultas() {
  const messages = useRef(null);
  const ERROR_MESSAGE =
    "Ocurrió un error en la consulta, por favor intentarlo nuevamente.";
  const NO_CONSULTAS_MESSAGE = "Usted no tiene consultas pendientes.";
  const consultas = useAxios("GET", "", true);
  const [dni, setDni] = useState("");
  const [nombre, setNombre] = useState("Ingrese su DNI");
  const [consultasVisibles, setConsultasVisibles] = useState(false);

  const resetearBloque = () => {
    setNombre("");
    setConsultasVisibles(false);
  };

  useEffect(() => {
    consultas.datos.length !== 0 && setNombre(consultas.datos[0].PACIENTE);
    comprobarRespuesta();
    return () => {};
    //eslint-disable-next-line
  }, [consultas.datos]);

  const comprobarRespuesta = () => {
    if (consultas.respuestaServer === 200) {
      if (consultas.datos.length !== 0) {
        setConsultasVisibles(true);
      } else {
        messages.current.show({
          severity: "warn",
          summary: "PACIENTE SIN CONSULTAS : ",
          detail: NO_CONSULTAS_MESSAGE,
        });
      }
    }
    if (consultas.respuestaServer === 400) {
      resetearBloque();
      messages.current.show({
        severity: "error",
        summary: "ERROR DE SEVIDOR",
        detail: ERROR_MESSAGE,
      });
    }
  };

  const iniciarConsulta = async (e) => {
    e.preventDefault();
    resetearBloque();
    consultas.actualizarUrl(`/api/consultas/${dni}`);
    consultas.iniciarPeticion();
  };

  return (
    <div>
      <h1 class="titulo">Consulta tus citas</h1>
      <BarraBusqueda
        dni={dni}
        setDni={setDni}
        iniciarConsulta={iniciarConsulta}
        loading={consultas.cargando}
      />
      <div className="w-50 m-auto">
        <Messages ref={messages}></Messages>
      </div>
      <div
        className={consultasVisibles ? "bloque activo" : "bloque desactivado"}
        style={{ minHeight: "68vh" }}
      >
        <p className="bienvenida">
          ¡Hola, Bienvenido(a) <strong>{nombre}</strong>!
        </p>
        <div>
          <div className="card-tickes">
            <h2 className="card-title-tickes">
              <strong>Tiene las siguientes citas:</strong>
            </h2>
            {consultas.datos.map((c) => (
              <Ticket c={c} />
            ))}
          </div>
        </div>
      </div>
      <div className="content-logo-footer">
        <span>HAPCSRII-2 © UEI 2023. </span>
        <Logo />
      </div>
    </div>
  );
}

export default Consultas;
//<ErrorAlert visible={visible} setVisible={setVisible} message = {mensajeFeed}/>
