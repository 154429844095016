import { useEffect } from 'react'
import { useState } from 'react'
import Axios from 'axios'
import { HOST_API, PARAMS_HOST } from '../Api/API'
import { axiosNotification } from 'core/utils/notificaciones'
function useAxios (
  metodo,
  url,
  noEjectuarAlInicio = false,
  parametros = {},
  axiosLoader = false
) {
  const [respuestaServer, setRespuestaServer] = useState(null)
  const [datos, setDatos] = useState([])
  const [error, setError] = useState(null)
  const [cargando, setCargando] = useState(false)
  const resetearConsulta = () => {
    setRespuestaServer(null)
    setDatos([])
    setError(null)
    setCargando(false)
  }
  const actualizarParametros = nuevosParametros => {
    parametros = nuevosParametros
  }
  const actualizarUrl = nuevaUrl => {
    url = nuevaUrl
  }
  const crearInstanciaAxios = () => {
    const instance = Axios.create({
      // baseURL: "http://192.168.1.159:5000",
      baseURL: HOST_API
      // baseURL: "http://localhost:5000",
    })
    return instance
  }
  const enviarPeticion = async instance => {
    let respuesta = null
    respuesta = await instance.post(PARAMS_HOST, {
      method: metodo,
      route: url,
      params: parametros
    })
    return respuesta
  }
  const iniciarPeticion = () => {
    resetearConsulta()
    recargarPeticion()
  }

  useEffect(() => {
    axiosLoader && axiosNotification(cargando, respuestaServer)
    return () => {}
  }, [axiosLoader, cargando, respuestaServer])

  const recargarPeticion = async () => {
    setCargando(true)
    try {
      const instanciaAxios = crearInstanciaAxios()
      const respuesta = await enviarPeticion(instanciaAxios)
      setRespuestaServer(200)
      respuesta && setDatos(respuesta.data)
      setCargando(false)
    } catch (error) {
      setRespuestaServer(400)
      setDatos([])
      setCargando(false)
      setError(error)
    }
  }

  useEffect(() => {
    if (!noEjectuarAlInicio) {
      iniciarPeticion()
    }
    return () => {}
    //eslint-disable-next-line
  }, [noEjectuarAlInicio])
  return {
    datos,
    cargando,
    error,
    setDatos,
    actualizarParametros,
    iniciarPeticion,
    recargarPeticion,
    actualizarUrl,
    resetearConsulta,
    respuestaServer
  }
}

export default useAxios
